import React, { Component } from "react";
import { Card, CardContent, Typography, Grow } from "@material-ui/core";

import "./ProjectCard.css";

export class ProjectCard extends Component {
  render() {
    const { name, pic, summary, link, id } = this.props;

    return (
      <Grow in={true} timeout={500 * id}>
        <Card className="project_cardBody" raised>
          <CardContent className="project_card_contentBox">
            <div className="project_title_box">
              <a href={link}>
                <Typography className="project_title">{name}</Typography>
              </a>
            </div>
            <div className="project_imgBox">
              <img src={pic} alt={`${name} pic`} className="project_img" />
            </div>
            <div className="project_text_box">
              <Typography className="project_text">{summary}</Typography>
            </div>
          </CardContent>
        </Card>
      </Grow>
    );
  }
}

export default ProjectCard;
