/** MIGHT BE UNNECESSARY! */

import React from "react";
import GlobalContext from "./GlobalContext";

/**
 * Images
 */
// import dannyFreudigerImg from "../data/images/officers/danny_freudiger.jpg";
// import johnDemayImg from "../data/images/officers/john_demay.jpg";

/**
 * JSON files
 */
// import aboutJSON from '../data/about.json';
// import officerJSON from '../data/officers.json';
// import projectJSON from '../data/projects.json';
// import partnerJSON from '../data/partners.json';
// import newsJSON from '../data/news.json';
// import awardJSON from '../data/awards.json';

class GlobalProvider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const global = {
      data: this.state
    };

    return (
      <GlobalContext.Provider value={global}>
        {this.props.children}
      </GlobalContext.Provider>
    );
  }
}

export default GlobalProvider;
