import React, { Component } from "react";
import { Typography } from "@material-ui/core";
import ContentCardWide from "../Cards/ContentCardWide/index";
import ProjectCard from "../Cards/ProjectCard/index";

import "./Awards.css";

import data from "../../data/awards.json";

const d = data.awards;

export class Awards extends Component {
  render() {
    return (
      <div id="awards">
        <div id="awards_content">
          <Typography className="section_header">Awards</Typography>
          <div id="awards_card_box">
            {d.map(a => (
              !this.props.isMobile ?
              <div class="news_card_entry">
                <ContentCardWide
                  key={a.award_id}
                  id={a.award_id}
                  img={a.award_pic}
                  title={a.award_title}
                  summary={a.award_summary}
                  link={a.award_link}
                /></div> :
                <div class="news_card_entry">
                <ProjectCard
                    key={a.award_id}
                    id={a.award_id}
                    name={a.award_title}
                    pic={a.award_pic}
                    summary={a.award_summary}
                    link={a.award_link}
                  />
                  </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default Awards;
