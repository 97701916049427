import { createMuiTheme } from "@material-ui/core/styles";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import { white } from "ansi-colors";

const breakpoints = createBreakpoints({});

export const theme = createMuiTheme({
  breakpoints,
  typography: {
    useNextVariants: true,
    fontFamily: ["Roboto", "Muli", "serif"]
  },
  palette: {
    background: {
      default: "#ffffff",
      secondary: white,
      paper: `whitesmoke`
    }
  },
  screen: {
    background: white,
    display: "flex",
    height: "100vh"
  },
  overrides: {
    MuiButton: {
      root: {
        fontSize: "1rem"
      }
    }
  }
});
