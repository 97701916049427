import React, { Component } from "react";

import "./About.css";

import d from "../../data/about.json";
import { Typography } from "@material-ui/core";

export class About extends Component {
  render() {
    return (
      <div id="about">
        <div id="aboutContent">
          <Typography className="section_header">Our Mission...</Typography>
          <Typography id="aboutText">{d.mission}</Typography>
        </div>
      </div>
    );
  }
}

export default About;
