import React from "react";
import { SwipeableDrawer } from "@material-ui/core";
import "./Drawer.css";
import { makeStyles } from "@material-ui/core/styles";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";

export default function Drawer() {
  const useStyles = makeStyles({
    list: {
      width: 250
    },
    fullList: {
      width: "auto"
    }
  });

  const classes = useStyles();
  const [state, setState] = React.useState({
    opened: false
  });

  const toggleDrawer = open => event => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, opened: open });
  };

  const sideList = () => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {["About", "News", "Officers", "Projects", "Partners", "Awards"].map(
          (text, index) => (
            <ListItem
              button
              key={text}
              component="a"
              href={"#" + text.toLowerCase()}
            >
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          )
        )}
      </List>
    </div>
  );

  return (
    <div>
      <SwipeableDrawer
        open={state.opened}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        {sideList()}
      </SwipeableDrawer>
    </div>
  );
}
