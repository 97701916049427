import React, { Component } from "react";

import "./Landing.css";

export class Landing extends Component {
  render() {
    return (
      <div id="home">
        {/* TODO: Image carosel to show flyers with page */}
        <div id="coverContainer">
          <img
            src="https://smartcampuswebsite.s3.us-east-2.amazonaws.com/cover-photo-1500w.jpg"
            alt="Cover"
            id="coverImg"
          />
        </div>
      </div>
    );
  }
}

export default Landing;
