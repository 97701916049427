import React, { Component } from "react";

import { Typography } from "@material-ui/core";

import "./Contact.css";

export class Contact extends Component {
  renderContactDiv() {
    if (this.props.isMobile) return null;
    return (
      <div id="contact">
        <div id="contactContent">
          <Typography className="section_header">Contact us!</Typography>
          <Typography className="contact_text">
            If you are interested in learning more about Smart Campus please
            email Anita at <b>nti.6@osu.edu</b>
          </Typography>
        </div>
      </div>
    );
  }

  render() {
    return <div>{this.renderContactDiv()}</div>;
  }
}

export default Contact;
