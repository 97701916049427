import React, { Component } from "react";
import { Typography } from "@material-ui/core";

import "./OfficerCard.css";

export class OfficerCard extends Component {
  render() {
    const { headshot, name, title } = this.props;

    return (
      <div className="officer_cardBody">
        <div className="officer_card_contentBox">
          <div className="officer_headshotBox">
            <img
              src={headshot}
              alt={`${name} headshot`}
              className="officer_headshotImg"
            />
          </div>
          <div className="officer_textContentBox">
            <Typography variant="h5" className="officer_name">
              {name}
            </Typography>
            <Typography variant="h6">{title}</Typography>
          </div>
        </div>
      </div>
    );
  }
}

export default OfficerCard;
