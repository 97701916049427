import React, { Component } from "react";
import { AppBar, Toolbar, Button } from "@material-ui/core";
// import logo from "../../data/images/red-on-clear.png";
import "./Navbar.css";
import MenuIcon from "@material-ui/icons/Menu";

export class Navbar extends Component {
  renderNavbar() {
    if (this.props.isMobile) {
      const mobileStyle = {
        display: "block",
        marginRight: "auto",
        marginLeft: "auto",
        // this is hacky and probably shouldn't be done in production
        paddingRight: "2em"
      };
      return (
        <div className="navContent">
          <MenuIcon className="hamMenu" />
          <img
            src="https://smartcampuswebsite.s3.us-east-2.amazonaws.com/red-on-clear.png"
            alt="Smart Campus OSU"
            id="logoImg"
            style={mobileStyle}
          />
        </div>
      );
    } else {
      return (
        <AppBar position="fixed" id="navBody">
          <Toolbar>
            <a href="#home" id="logoContainer">
              <img
                src="https://smartcampuswebsite.s3.us-east-2.amazonaws.com/red-on-clear.png"
                alt="Smart Campus OSU"
                id="logoImg"
              />
            </a>
            {/* <Typography id="navTitle">Smart Campus OSU</Typography> */}
            <div id="navButtonBox">
              <Button className="navButton" href="#about">
                About
              </Button>
              <Button className="navButton" href="#news">
                News
              </Button>
              <Button className="navButton" href="#officers">
                Officers
              </Button>
              <Button className="navButton" href="#projects">
                Projects
              </Button>
              <Button className="navButton" href="#partners">
                Partners
              </Button>
              <Button className="navButton" href="#awards">
                Awards
              </Button>
              <Button className="navButton" href="#contact" edge="end">
                Contact
              </Button>
            </div>
          </Toolbar>
        </AppBar>
      );
    }
  }

  render() {
    return <div>{this.renderNavbar()}</div>;
  }
}

export default Navbar;
