import React, { Component } from "react";

import "./Partners.css";
import { Typography } from "@material-ui/core";

export class Partners extends Component {
  render() {
    return (
      <div id="partners">
        <div id="partners_content">
          <Typography className="section_header">Our Partners</Typography>
          <div id="partnerImgBox">
            <img
              src="https://smartcampuswebsite.s3.us-east-2.amazonaws.com/partners/partner_collage.png"
              alt="Partners"
              id="partnersImg"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Partners;
