import React, { Component } from "react";
import { Card, CardContent, Typography, Grow } from "@material-ui/core";

import "./ContentCardWide.css";

export class ContentCardWide extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { img, title, summary, link, id } = this.props;

    return (
      <Grow in={true} timeout={500 * id}>
        <Card className="cardBody" raised={true}>
          <CardContent className="contentBox">
            <div className="imgBox">
              <img src={img} alt="Content Pic" className="contentImg" />
            </div>
            <div className="textContentBox">
              <a href={link}>
                <Typography className="cardTitle">{title}</Typography>
              </a>
              <Typography>{summary}</Typography>
            </div>
          </CardContent>
        </Card>
      </Grow>
    );
  }
}

export default ContentCardWide;
