import React, { Component } from 'react';
import moment from 'moment';

import "./Flyer.css";
import data from "../../data/flyer.json";
import { Typography } from '@material-ui/core';

const { flyer_img, flyer_title, flyer_experation_date } = data;

export class Flyer extends Component {
    render() {
        const event_is_over = moment().isAfter(moment(flyer_experation_date));
        
        return (event_is_over ? null : (
            <div>
                <Typography className="section_header">Our Next Meeting</Typography>
                <div id="flyer_container">
                    <img src={flyer_img} alt={flyer_title} />
                </div>
            </div>
        ));
    }
}

export default Flyer;