import { combineReducers } from "redux";

const initialState = {
  isMobile: false
};

function isMobile(state = initialState, action) {
  console.log(action);
  if (action.type === "SET_IS_MOBILE") {
    return Object.assign({}, state, {
      isMobile: action.isMobile
    });
  }

  return state;
}

const appReducers = combineReducers({
  isMobile
});

export default appReducers;
