// Library Imports
import React, { Component } from "react";
import GlobalProvider from "./lib/GlobalProvider";
import { MuiThemeProvider } from "@material-ui/core";
import { theme } from "./lib/Theme";
import "typeface-roboto";

import "./App.css";

// Screen Imports
import Home from "./Screens/Home/index";

export default class App extends Component {
  render() {
    return (
      <div>
        <MuiThemeProvider theme={theme}>
          <GlobalProvider>
            <Home />
          </GlobalProvider>
        </MuiThemeProvider>
      </div>
    );
  }
}
