import React, { Component } from "react";
import ContentCardWide from "../Cards/ContentCardWide/index";
import ProjectCard from "../Cards/ProjectCard/index";

import data from "../../data/news.json";

import "./News.css";
import { Typography } from "@material-ui/core";

const d = data.news;

export class News extends Component {
  render() {
    return (
      <div id="news">
        <div id="newsContent">
          <Typography className="section_header">News</Typography>
          {d.map(n => (
            !this.props.isMobile ?
            <div class="news_card_entry">
              <ContentCardWide
                key={n.article_id}
                id={n.article_id}
                img={n.article_pic}
                title={n.article_header}
                summary={n.article_summary}
                link={n.article_link}
                isMobile={this.props.isMobile}
              /></div> :
              <div class="news_card_entry">
              <ProjectCard
                  key={n.article_id}
                  id={n.article_id}
                  name={n.article_header}
                  pic={n.article_pic}
                  summary={n.article_summary}
                  link={n.article_link}
                />
                </div>
          ))}
        </div>
      </div>
    );
  }
}

export default News;
