import React, { Component } from "react";
import { Typography } from "@material-ui/core";
import OfficerCard from "../Cards/OfficerCard/index";

import data from "../../data/officers.json";

import "./Officers.css";

const d = data.officers;

export class Officers extends Component {
  render() {
    return (
      <div id="officers">
        <div id="officerContent">
          <Typography className="section_header">Officers</Typography>
          <div id="officer_card_box">
            {d.map(n => (
              <OfficerCard
                key={n.officer_id}
                headshot={n.officer_pic}
                name={`${n.officer_first_name} ${n.officer_last_name}`}
                title={n.officer_position}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default Officers;
