import React, { Component } from "react";

import { Typography, IconButton } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";

import data from "../../data/footer.json";

import "./Footer.css";

const socialIcons = createMuiTheme({
  overrides: {
    MuiIconButton: {
      root: {
        fontSize: "2.5rem",
        paddingTop: 0
      }
    }
  }
});

export class Footer extends Component {
  render() {
    return (
      <div id="footer">
        <div id="footerContent">
        <div id="footerSocialIcons">
          <ThemeProvider theme={socialIcons}>
            {data.socials.map(social => (
              <IconButton 
                  key={social.social_name}
                  href={social.link}
                  className={social.class_name}
                  name={social.social_name} 
                />
            ))}
          </ThemeProvider>
        </div>

        <div>
          <Typography variant="h5" id="footer_copyright">©2021 Smart Campus OSU</Typography>{" "}
        </div>
          
        </div>
      </div>
    );
  }
}

export default Footer;
