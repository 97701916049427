import React, { Component } from "react";

import Navbar from "../../components/Navbar";
import Landing from "../../components/Landing";
import About from "../../components/About";
import Flyer from "../../components/Flyer";
import News from "../../components/News";
import Officers from "../../components/Officers";
import Projects from "../../components/Projects";
import Partners from "../../components/Partners";
import Awards from "../../components/Awards";
import Contact from "../../components/Contact";
import Footer from "../../components/Footer";
import Drawer from "../../components/Drawer";
import { setIsMobile } from "../../actions";

import store from "../../store";

import "./Home.css";


export class Home extends Component {

  constructor(props) {
    super(props);
    this.state = {isMobile : false};
  }

  componentDidMount() {
    window.addEventListener("resize", () => this.resize);
    this.resize();
  }

  resize() {
    const isMobile = window.innerWidth <= 1024;
    this.setState({
      isMobile
    });

    store.dispatch(setIsMobile(isMobile));
  }

  render() {
    return (
      <div>
        <Navbar isMobile={this.state.isMobile} />
        <div id="pageContent">
          <Drawer />
          <Landing />
          <About />
          <Flyer />
          <News isMobile={this.state.isMobile} />
          <Officers />
          <Projects isMobile={this.state.isMobile} />
          <Partners />
          <Awards isMobile={this.state.isMobile} />
          <Contact isMobile={this.state.isMobile} />
        </div>
        <Footer />
      </div>
    );
  }
}

export default Home;
